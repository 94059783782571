import React, { useState } from "react";
import { useStateContext } from "components/context";
import { AnimatePresence, motion } from "framer-motion";
import { InputMask } from "primereact/inputmask";
import axios from "axios";
import { IoCheckmarkSharp, IoClose, IoDocumentOutline } from "react-icons/io5";
import { useWindowSize } from "react-use";
import {
  backgroundVariants,
  descriptionVariants,
  blockVariants,
  inputVariants,
  textVariants,
} from "./animate";
import cx from "classnames";

import * as styles from "./callback.module.scss";

function Callback() {
  const { form, setForm } = useStateContext();
  const { height } = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    agreement: true,
    complete: false,
    status: "success",
  });

  const activeSubmite =
    contact.name !== "" &&
    (contact.phone || contact.email) !== "" &&
    contact.agreement;

  const formNameWithSlug = `ym_${form.id}`;

  const apiUrl = "/api/sendFormMail.php";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Ваш код для отправки данных формы на сервер
    axios
      .post(apiUrl, {
        form_name: form.name,
        user_name: contact.name,
        user_phone: contact.phone,
        user_email: contact.email,
        user_message: contact.message,
      })
      .then(function () {
        // Отправляем отчет о форме в яндекс метрику
        window.ym(76220497, "reachGoal", formNameWithSlug);

        setContact((prev) => ({ ...prev, complete: true }));
      })
      .catch((err) => console.log(err));

    // Очистка полей формы после отправки
  };

  return (
    <AnimatePresence initial={false}>
      {form.visible && (
        <section
          className={styles.container}
          style={{ height: height }}
          key={`callback_container`}
        >
          <motion.div
            variants={backgroundVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.background}
            onClick={() => setForm({ ...form, visible: false })}
          />
          <motion.div
            variants={blockVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.block}
            data-lenis-prevent
          >
            <div
              className={cx(
                styles.form,
                contact.complete &&
                  contact.status === "success" &&
                  styles.complete
              )}
            >
              <form
                className={styles.wrap}
                onSubmit={handleSubmit}
                id={`callback_form`}
              >
                <div className={styles.head}>
                  <h2>
                    <span className="part-container">
                      <AnimatePresence mode="popLayout">
                        {contact.complete ? (
                          <motion.span
                            key={`title_complete`}
                            variants={textVariants}
                            custom={{ delay: 0.45, duration: 0.7 }}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition="transition"
                          >
                            {contact.status === "success"
                              ? "Спасибо за заявку"
                              : "Что-то пошло не так..."}
                          </motion.span>
                        ) : (
                          <motion.span
                            key={`title`}
                            variants={textVariants}
                            custom={{ delay: 0.45, duration: 0.7 }}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition="transition"
                          >
                            {form.title}
                          </motion.span>
                        )}
                      </AnimatePresence>
                    </span>
                  </h2>
                  <button
                    type="button"
                    className={cx("button-close", styles.desktop)}
                    onClick={() => setForm({ ...form, visible: false })}
                  >
                    <IoClose />
                  </button>
                </div>
                <AnimatePresence>
                  <motion.p
                    variants={descriptionVariants}
                    custom={{ delay: 0.5, duration: 0.6 }}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition="transition"
                    className={styles.description}
                  >
                    {form.description}
                  </motion.p>
                </AnimatePresence>

                <div className={styles.inputs}>
                  <div className={styles.item}>
                    <span className={styles.label}>
                      <span className="part-container">
                        <motion.span
                          variants={textVariants}
                          custom={{ delay: 0.5, duration: 0.6 }}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          transition="transition"
                        >
                          Имя
                        </motion.span>
                      </span>
                    </span>
                    <motion.input
                      variants={inputVariants}
                      custom={{ delay: 0.5, duration: 0.8 }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition="transition"
                      required={false}
                      autoComplete="off"
                      value={contact.name}
                      disabled={contact.complete || isLoading}
                      placeholder="Как к Вам обращаться"
                      name="user_name"
                      onChange={(e) =>
                        setContact((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className={styles.item}>
                    <span className={styles.label}>
                      <span className="part-container">
                        <motion.span
                          variants={textVariants}
                          custom={{ delay: 0.6, duration: 0.6 }}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          transition="transition"
                        >
                          Телефон
                        </motion.span>
                      </span>
                    </span>
                    <motion.div
                      variants={inputVariants}
                      custom={{ delay: 0.6, duration: 0.8 }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition="transition"
                    >
                      <InputMask
                        autoComplete="off"
                        value={contact.phone}
                        disabled={contact.complete || isLoading}
                        placeholder="Введите номер телефона"
                        name="user_phone"
                        onChange={(e) =>
                          setContact((prev) => ({
                            ...prev,
                            phone: e.target.value,
                          }))
                        }
                        mask="+7 (999) 999-99-99"
                      />
                    </motion.div>
                  </div>

                  <div className={styles.item}>
                    <span className={styles.label}>
                      <span className="part-container">
                        <motion.span
                          variants={textVariants}
                          custom={{ delay: 0.7, duration: 0.6 }}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          transition="transition"
                        >
                          Почта
                        </motion.span>
                      </span>
                    </span>
                    <motion.input
                      variants={inputVariants}
                      custom={{ delay: 0.7, duration: 0.8 }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition="transition"
                      required={false}
                      autoComplete="off"
                      value={contact.email}
                      disabled={contact.complete || isLoading}
                      placeholder="Введите email"
                      name="user_social"
                      onChange={(e) =>
                        setContact((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className={styles.item}>
                    <span className={styles.label}>
                      <span className="part-container">
                        <motion.span
                          variants={textVariants}
                          custom={{ delay: 0.8, duration: 0.6 }}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          transition="transition"
                        >
                          Сообщение
                        </motion.span>
                      </span>
                    </span>
                    <motion.textarea
                      variants={inputVariants}
                      custom={{ delay: 0.8, duration: 0.8 }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition="transition"
                      required={false}
                      autoComplete="off"
                      rows={5}
                      value={contact.message}
                      disabled={contact.complete || isLoading}
                      placeholder="Введите сообщение или вопрос"
                      name="user_message"
                      onChange={(e) =>
                        setContact((prev) => ({
                          ...prev,
                          message: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className={styles.action}>
                  <motion.button
                    variants={inputVariants}
                    custom={{ delay: 0.75, duration: 0.6 }}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition="transition"
                    className={cx(
                      styles.checkbox,
                      contact.agreement && styles.checked
                    )}
                    type="button"
                    onClick={() =>
                      setContact((prev) => ({
                        ...prev,
                        agreement: !prev.agreement,
                      }))
                    }
                  >
                    <div className={styles.box}>
                      <IoCheckmarkSharp className={styles.icon} />
                    </div>
                    <p className={styles.label}>
                      Я согласен(-на) на обработку моих персональных данных
                    </p>
                  </motion.button>

                  <motion.div
                    variants={inputVariants}
                    custom={{ delay: 0.75, duration: 0.8 }}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition="transition"
                    className={styles.submit}
                  >
                    {contact.complete ? (
                      contact.status === "success" ? (
                        form.presentation ? (
                          <a
                            className={cx("button red full-width")}
                            href={`/${form.presentation}`}
                            download={form.presentation}
                          >
                            <IoDocumentOutline />
                            <p>Скачать комплектацию</p>
                          </a>
                        ) : (
                          <button
                            type="button"
                            className={cx("button red full-width")}
                            onClick={() => setForm({ ...form, visible: false })}
                          >
                            Вернуться к сайту
                          </button>
                        )
                      ) : (
                        <button
                          className={cx(
                            "button red full-width",
                            !activeSubmite && "nonactive",
                            isLoading && "loading"
                          )}
                          type="submit"
                          id="callback_form"
                        >
                          Повторить отправку
                        </button>
                      )
                    ) : (
                      <button
                        className={cx(
                          "button full-width",
                          activeSubmite ? "red" : "grey",
                          isLoading && "loading"
                        )}
                        disabled={!activeSubmite}
                        type="submit"
                        id="callback_form"
                      >
                        {isLoading
                          ? "Отправляем..."
                          : activeSubmite
                          ? "Отправить"
                          : "Заполните поля"}
                      </button>
                    )}
                    <button
                      type="button"
                      className={cx("button-close red", styles.mobile)}
                      onClick={() => setForm({ ...form, visible: false })}
                    >
                      <IoClose />
                    </button>
                  </motion.div>
                </div>
              </form>
            </div>
          </motion.div>
        </section>
      )}
    </AnimatePresence>
  );
}

export default Callback;
