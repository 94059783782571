// extracted by mini-css-extract-plugin
export var action = "callback-module--action--1e791";
export var background = "callback-module--background--ae326";
export var block = "callback-module--block--59afc";
export var box = "callback-module--box--3472e";
export var checkbox = "callback-module--checkbox--0e3b6";
export var checked = "callback-module--checked--8d835";
export var complete = "callback-module--complete--8fe1e";
export var container = "callback-module--container--ca108";
export var description = "callback-module--description--5b80e";
export var desktop = "callback-module--desktop--b886c";
export var form = "callback-module--form--8ebb5";
export var head = "callback-module--head--c2759";
export var icon = "callback-module--icon--5ef81";
export var inputs = "callback-module--inputs--05e91";
export var item = "callback-module--item--fd799";
export var label = "callback-module--label--aebe5";
export var mobile = "callback-module--mobile--9f6f9";
export var submit = "callback-module--submit--1138d";
export var wrap = "callback-module--wrap--04be4";