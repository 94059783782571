import React, { lazy, StrictMode, Suspense, useEffect } from "react";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { useLocation } from "react-use";
import { useIsMobile, useIsDesktop } from "../hooks/mediaQuery";

import { ContextProvider } from "components/context";

import Callback from "./modal/callback";

const Header = lazy(() => import("./header"));
const Mobileheader = lazy(() => import("./mobileheader"));

const queryClient = new QueryClient();

const options = {
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  direction: "vertical",
  gestureDirection: "vertical",
  smooth: true,
  mouseMultiplier: 1,
  smoothTouch: false,
  touchMultiplier: 2,
  infinite: false,
};

function Layout({ children }) {
  const location = useLocation();
  const lenis = useLenis();

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (!location.hash.includes("#")) {
      lenis?.scrollTo(0, {
        immediate: true,
      });
    }
  }, [location.pathname]);

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <Suspense fallback={null}>{isMobile && <Mobileheader />}</Suspense>
          <Suspense fallback={null}>{isDesktop && <Header />}</Suspense>

          <ReactLenis root options={options}>
            <main>{children}</main>
            <Callback />
          </ReactLenis>
        </ContextProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}

export default Layout;
